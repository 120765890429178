
import axios from "axios"
import wx from 'weixin-js-sdk';
import { useQRCode } from '@vueuse/integrations/useQRCode'
const qrcode = useQRCode("123")

const test_config = { appid:"wx5a0e37d784dd081d", timestamp:1650436487, nonceStr:"j626h2aXExhQwD3M", signature:"6619f11e2858b7782405cd8fdcb0c6902c6d9729", jsapiTicket:"HoagFKDcsGMVCIY2vOjf9lHCsHPD2H_oEAmbqOBnwCPB3DLJ00pmVc1oraRI0bKzl407l0Xh0F7CuXJPLKnTpg", url:null, jsApiList:{api:"#"} }

function wxShare(share_info) {
  open(test_config,share_info)
  axios.get('https://jy.gxb.cn/wechat/share',/* { url: 'http://www.gt0571.com/content/6970' } */ ).then(ok=>{
    const { status, data, msg} = ok.data
    status?open(data,share_info):console.log(msg);
  })
}

function open(config,share) {
  console.log(config,share);
  wx.config({
    appId: config.appid, // 和获取Ticke的必须一样------必填，公众号的唯一标识
    timestamp: config.timestamp, // 必填，生成签名的时间戳
    nonceStr: config.nonceStr, // 必填，生成签名的随机串
    signature: config.signature,// 必填，签名，见附录1
    //需要分享的列表项:发送给朋友，分享到朋友圈，分享到QQ，分享到QQ空间
    jsApiList: [
      'onMenuShareAppMessage', 'onMenuShareTimeline',
    ]
  });
  //监听分享动作
  wx.ready(function () {
    //分享给好友
    wx.onMenuShareAppMessage({
      title: share.title, // 分享标题
      desc: share.desc,	//分享的描述
      link: share.link, // 分享链接，该链接域名必须与当前企业的可信域名一致
      type: share.type, // 分享类型,music、video或link，不填默认为link
      imgUrl: share.imgUrl, // 分享图标
      success: function () {
        console.log('分享成功')
      },
    });
    //分享到朋友圈
    wx.onMenuShareAppMessage({
      title: shareMsage.title, // 分享标题
      desc: shareMsage.desc,	//分享的描述
      link: shareMsage.link, // 分享链接，该链接域名必须与当前企业的可信域名一致
      type: shareMsage.type, // 分享类型,music、video或link，不填默认为link
      imgUrl: shareMsage.imgUrl, // 分享图标
      success: function () {
        console.log('分享成功')
      },
    });
  })
}




export default wxShare